<template>
	<div>
		<CreateDialog :dialog="serviceDialog" v-on:close="$emit('close', true)" :back="false">
			<template v-slot:title>{{ type }} Service</template>
			<template v-slot:body>
				<v-form
					ref="serviceForm"
					v-model.trim="formValid"
					lazy-validation
					v-on:submit.stop.prevent="onSubmit"
				>
					<v-row v-if="serviceDialog" class="service-dialog">
						<v-col md="8">
							<v-row>
								<v-col md="12" v-if="relatedDetails && relatedDetails.name">
									<perfect-scrollbar
										:options="{ suppressScrollX: true }"
										class="scroll"
										style="max-height: 60vh; position: static"
									>
										<v-list class="border border-secondary light-blue-bg" subheader three-line>
											<v-list-item>
												<v-list-item-avatar v-if="relatedDetails">
													<ImageTemplate circle :src="relatedDetails.image"></ImageTemplate>
												</v-list-item-avatar>
												<v-list-item-content v-if="relatedDetails">
													<v-list-item-title class="font-size-16">
														<Chip color="blue darken-4" :text="relatedDetails.barcode"></Chip>
													</v-list-item-title>
													<v-list-item-subtitle class="font-size-14">
														<b>{{ relatedDetails.name }}</b>
													</v-list-item-subtitle>
												</v-list-item-content>
												<v-list-item-action class="margin-auto" v-if="relatedDetails">
													<v-list-item-action-text class="font-size-16"
														><b>Cost</b> {{ relatedDetails.cost_price_formatted }}
													</v-list-item-action-text>
													<v-list-item-action-text
														v-if="relatedDetails && relatedDetails.group_relation"
														class="font-size-16"
														><b>Category</b> {{ relatedDetails.group_relation.name }}
													</v-list-item-action-text>
												</v-list-item-action>
											</v-list-item>
										</v-list>
									</perfect-scrollbar>
								</v-col>
								<template v-if="service_setting && service_setting.recurring">
									<v-col md="4" class="py-0">
										<label :for="`service-from-${uniqFieldId}`" class="btx-label mt-3 required"
											>Service Type</label
										>
									</v-col>
									<v-col md="8" class="py-0">
										<NewRadioInput
											row
											hide-details
											v-model="service.serviceCategory"
											:disabled="pageLoading"
											:id="`service-performed-by-${uniqFieldId}`"
											:items="serviceType"
										></NewRadioInput>
									</v-col>
								</template>
								<template v-if="service.serviceCategory == 1">
									<v-col md="4" class="my-auto py-0">
										<label :for="`expected-start-date-${uniqFieldId}`" class="btx-label mt-3"
											>Expected Start Date</label
										>
									</v-col>
									<v-col md="8" class="py-0">
										<DateTimePicker
											hide-details
											:min-date="today"
											:disabled="pageLoading"
											:loading="pageLoading"
											:id="`expected-start-date-${uniqFieldId}`"
											placeholder="Expected Start Date"
											v-model="service.start_date"
											v-on:change="updateDates()"
										></DateTimePicker>
									</v-col>
									<v-col md="4" class="my-auto py-0">
										<label :for="`expected-end-date-${uniqFieldId}`" class="btx-label mt-3"
											>Expected End Date</label
										>
									</v-col>
									<v-col md="8" class="py-0">
										<DateTimePicker
											hide-details
											:min-date="service.start_date"
											:disabled="pageLoading"
											:loading="pageLoading"
											:id="`expected-end-date-${uniqFieldId}`"
											placeholder="Expected End Date"
											v-model="service.end_date"
										></DateTimePicker>
									</v-col>
								</template>
								<template v-else-if="service.serviceCategory == 2">
									<v-col md="12" class="py-0">
										<RecurringService />
									</v-col>
								</template>
								<v-col md="4" class="py-0"> </v-col>
								<v-col md="8" class="py-0">
									<v-checkbox
										dense
										hide-details
										label="Put item into maintenance and make it unavailable at the starting date."
										class="mt-3 mb-0 p-0"
										v-model="service.maintenance"
									></v-checkbox>
								</v-col>
								<v-col md="4" class="my-auto py-0">
									<label :for="`service-type-${uniqFieldId}`" class="btx-label mt-3 required"
										>Service Category</label
									>
								</v-col>
								<v-col md="8" class="py-0">
									<AutoCompleteInput
										hide-details
										:items="serviceTypeList"
										:disabled="pageLoading"
										:loading="pageLoading"
										:id="`service-type-${uniqFieldId}`"
										placeholder="Service Category"
										v-model="service.service_type"
										:rules="[vrules.required(service.service_type, 'Service Category')]"
										:class="{
											required: !service.service_type,
										}"
										v-on:click:append-outer="manageServiceTypeDialog = true"
										append-outer-icon="mdi-cog"
									></AutoCompleteInput>
								</v-col>
								<v-col md="4" class="my-auto py-0">
									<label :for="`service-performed-by-${uniqFieldId}`" class="btx-label mt-3"
										>Service Performed by</label
									>
								</v-col>
								<v-col md="8" class="py-0">
									<RadioInput
										row
										hide-details
										v-model="service.performed_by"
										:disabled="pageLoading"
										:id="`service-performed-by-${uniqFieldId}`"
										:items="performedItems"
									></RadioInput>
								</v-col>
								<v-col md="4" class="my-auto py-0">
									<label :for="`service-performed-by-${uniqFieldId}`" class="btx-label mt-3"
										>Service Location</label
									>
								</v-col>
								<v-col md="8" class="py-0">
									<RadioInput
										row
										hide-details
										v-model="service.location_by"
										:disabled="pageLoading"
										:id="`service-performed-by-${uniqFieldId}`"
										:items="performedPlaceItems"
									></RadioInput>
								</v-col>
								<template v-if="service.performed_by == 1">
									<v-col md="4" class="my-auto py-0">
										<label :for="`vendor-${uniqFieldId}`" class="btx-label mt-3">Supplier</label>
									</v-col>
									<v-col md="8" class="py-0">
										<AutoCompleteInput
											hide-details
											:items="vendorList"
											:disabled="pageLoading"
											:loading="pageLoading"
											:id="`vendor-${uniqFieldId}`"
											placeholder="Supplier"
											v-model="service.vendor"
										></AutoCompleteInput>
									</v-col>
								</template>
								<template v-else>
									<v-col md="4" class="my-auto py-0">
										<label :for="`member-${uniqFieldId}`" class="btx-label mt-3">Staff</label>
									</v-col>
									<v-col md="8" class="py-0">
										<AutoCompleteInput
											hide-details
											:items="memberList"
											:disabled="pageLoading"
											:loading="pageLoading"
											:id="`member-${uniqFieldId}`"
											placeholder="Staff"
											v-model="service.member"
										></AutoCompleteInput>
									</v-col>
								</template>
								<v-col md="4" class="py-0">
									<label :for="`description-${uniqFieldId}`" class="btx-label mt-3">Description</label>
								</v-col>
								<v-col md="8" class="py-0">
									<TextAreaInput
										hide-details
										:disabled="pageLoading"
										:loading="pageLoading"
										:id="`description-${uniqFieldId}`"
										placeholder="Description"
										v-model="service.description"
									></TextAreaInput>
								</v-col>
								<v-col md="12" class="font-level-3-bold pb-3 border-bottom-light-grey text-black mt-4 py-0">
									Attachments
								</v-col>
								<v-col md="12" class="py-0 mt-4">
									<template v-for="(data, index) in service.attachment">
										<v-row :key="`file-row-${index}`" :class="{ 'table-alternate-row': index % 2 }">
											<v-col md="5" class="py-0">
												<v-file-input
													v-model="data.file"
													placeholder="Click here to select files"
													color="blue darken-4"
													multiple
													outlined
													class="mt-3"
													prepend-icon=""
													prepend-inner-icon="mdi-attachment"
													hide-details
													v-on:change="limitAttachment($event, index)"
												></v-file-input>
											</v-col>
											<v-col md="5" class="py-0">
												<TextInput hide-details placeholder="File Name" v-model="data.name"></TextInput>
											</v-col>
											<v-col md="1" class="py-0">
												<v-btn
													:disabled="service.attachment.length < 2"
													v-on:click="removeFile(index)"
													class="white--text mt-3"
													depressed
													color="red darken-4"
													tile
													style="margin-left: -10px"
												>
													<v-icon>mdi-delete</v-icon>
												</v-btn>
											</v-col>
											<v-col md="1" class="py-0">
												<v-btn
													:disabled="service.attachment.length > 2"
													v-on:click="addMore()"
													class="white--text mt-3"
													depressed
													color="blue darken-4"
													tile
													style="margin-left: -13px"
												>
													<v-icon>mdi-plus</v-icon>
												</v-btn>
											</v-col>
										</v-row>
									</template>
								</v-col>
							</v-row>
						</v-col>
						<v-col md="4">
							<template v-if="asset_setting && asset_setting.subpart">
								<v-col md="12" class="font-level-3-bold pb-3 border-bottom-light-grey text-black mt-4 py-0">
									Sub-Parts <br />
									<span class="font-level-0 text-muted">
										<v-icon small>mdi-progress-question</v-icon> Asset Sub-Parts</span
									>
								</v-col>
								<v-col md="12" class="py-0">
									<div class="mt-3 overflow-y border-light-grey" style="max-height: calc(100vh - 337px)">
										<table width="100%" class="detail-table table-head-sticky">
											<thead>
												<tr>
													<th width="10%" class="p-2 light-blue-bg">
														<v-checkbox
															dense
															hide-details
															class="mt-3 mb-0 p-0"
															v-model="checkAll"
															v-on:change="manageAllSubpartCheck"
														></v-checkbox>
													</th>
													<th width="10%" class="p-2 light-blue-bg">Image</th>
													<th width="35%" class="p-2 light-blue-bg">Name</th>
													<th width="45%" class="p-2 light-blue-bg">Remarks</th>
												</tr>
											</thead>
											<tbody v-if="service.subparts.length">
												<tr v-for="(row, index) in service.subparts" :key="index">
													<td class="px-2 pb-3 font-level-1 text-black border-top-light-grey">
														<v-checkbox
															dense
															hide-details
															class="mt-3 mb-0 p-0"
															v-model="row.status"
															v-on:change="validateAllCheck"
														></v-checkbox>
													</td>
													<td class="p-2 border-top-light-grey">
														<ImageTemplate circle style="max-width: 50px" :src="row.image"> </ImageTemplate>
													</td>
													<td class="px-2 pb-3 my-auto font-level-1 text-black border-top-light-grey">
														<p class="m-0 my-auto mt-3">{{ row.name }}</p>
													</td>
													<td class="px-2 pb-3 font-level-1 text-black border-top-light-grey">
														<TextAreaInput
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															:rows="1"
															:id="`description-${uniqFieldId}`"
															placeholder="Description"
															v-model="row.remarks"
														></TextAreaInput>
													</td>
												</tr>
											</tbody>
											<tfoot v-else>
												<tr>
													<td colspan="4">
														<p class="m-0 row-not-found text-center py-3">
															<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
															Uhh... There are no subparts for this asset.
														</p>
													</td>
												</tr>
											</tfoot>
										</table>
									</div>
								</v-col>
							</template>
						</v-col>
						<!-- <v-col md="12" class="py-0 mt-4">
							<UploadFile v-on:files="manageFiles"> </UploadFile>
						</v-col> -->
					</v-row>
				</v-form>
			</template>
			<template v-slot:action>
				<v-btn
					v-if="serviceUuid"
					class="white--text"
					:loading="pageLoading"
					:disabled="!formValid || pageLoading"
					depressed
					color="blue darken-4"
					tile
					v-on:click="onUpdateSubmit()"
				>
					Service Update
				</v-btn>
				<v-btn
					v-else
					class="white--text"
					:loading="pageLoading"
					:disabled="!formValid || pageLoading"
					depressed
					color="blue darken-4"
					tile
					v-on:click="onSubmit()"
				>
					Service
				</v-btn>
				<v-btn depressed tile :disabled="pageLoading" v-on:click="$emit('close', true)"> Close </v-btn>
			</template>
		</CreateDialog>
		<template v-if="manageServiceTypeDialog">
			<ManageServiceType
				:dialog="manageServiceTypeDialog"
				:serviceTypes="serviceTypeList"
				v-on:close-dialog="manageServiceTypeDialog = false"
				v-on:success="getServiceTypes"
			></ManageServiceType>
		</template>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import { toSafeInteger, map } from "lodash";
import CreateDialog from "@/view/components/CreateDialog";
import ApiService from "@/core/services/api.service";
import { SET_ERROR } from "@/core/services/store/common.module";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import RadioInput from "@/view/components/RadioInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import DateTimePicker from "@/view/components/DateTimePicker";
import { EventBus } from "@/core/event-bus/event.bus";
import ManageServiceType from "@/view/components/Manage-Service-Type.vue";
import MomentJS from "moment-timezone";
import ImageTemplate from "@/view/components/Image";
import Chip from "@/view/components/Chip";
import { UploadFiles } from "@/core/lib/upload.lib";
import ObjectPath from "object-path";
import NewRadioInput from "@/view/components/NewRadioInput";
import TextInput from "@/view/components/TextInput";
import RecurringService from "@/view/components/RecurringService";

MomentJS.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
	name: "service-template",
	title: "Service Template",
	props: {
		type: {
			type: String,
			default: null,
		},
		typeUuid: {
			type: String,
			default: null,
		},
		endpoint: {
			type: String,
			default: null,
		},
		serviceDialog: {
			type: Boolean,
			default: false,
		},
		serviceArray: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			relatedDetails: {},
			asset_setting: {},
			service_setting: {},
			formValid: true,
			checkAll: false,
			serviceButton: false,
			pageLoading: false,
			manageServiceTypeDialog: false,
			serviceTypeList: [],
			vendorList: [],
			serviceUuid: null,
			memberList: [],
			service: {
				start_date: null,
				end_date: null,
				maintenance: null,
				service_type: null,
				performed_by: null,
				location_by: null,
				vendor: null,
				member: null,
				description: null,
				attachment: [
					{
						file: null,
						url: null,
						name: null,
					},
				],
			},
			performedItems: [
				{
					label: "Supplier",
					value: 1,
				},
				{
					label: "Staff",
					value: 2,
				},
			],
			performedPlaceItems: [
				{
					label: "Onsite",
					value: 1,
				},
				{
					label: "Workshop",
					value: 2,
				},
			],
			serviceType: [
				{
					label: "One-off Service",
					value: 1,
					description: "A one time service",
				},
				{
					label: "Recurring Service",
					value: 2,
					description: "A contract service with repeating services",
				},
			],
		};
	},
	watch: {
		serviceArray(param) {
			if (param) {
				this.service = {
					start_date: param.expected_start_date,
					end_date: param.expected_end_date,
					maintenance: param.type == 1 ? true : false,
					service_type: param.service_type,
					performed_by: param.service_by,
					location_by: param.location_by,
					vendor: param.supplier,
					member: param.member,
					description: param.description,
				};
				this.serviceUuid = param.uuid;
			}
		},
	},
	components: {
		CreateDialog,
		RadioInput,
		ImageTemplate,
		DateTimePicker,
		NewRadioInput,
		ManageServiceType,
		TextAreaInput,
		TextInput,
		RecurringService,
		Chip,
		AutoCompleteInput,
	},
	methods: {
		addMore() {
			this.service.attachment.push({
				file: null,
				url: null,
				name: null,
			});
		},
		removeFile(index) {
			this.service.attachment.splice(index, 1);
			if (this.service.attachment.length < 1) {
				this.addMore();
			}
		},
		limitAttachment(output, index) {
			if (this.pageLoading) {
				return false;
			}

			const attachment = output.slice(0, 3);

			if (attachment.length <= 0) {
				return false;
			}

			const request = new FormData();

			for (let i = 0; i < attachment.length; i++) {
				request.append(`files[${i}]`, attachment[i]);
			}

			this.pageLoading = true;

			UploadFiles(request)
				.then((response) => {
					this.service.attachment[index].url = ObjectPath.get(response, "0.path", null);
					this.service.attachment[index].name = ObjectPath.get(response, "0.name", null);
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		manageAllSubpartCheck(val) {
			this.service.subparts = map(this.service.subparts, (row) => {
				row.status = val;
				return row;
			});
		},
		validateAllCheck() {
			let validFlag = true;
			if (this.service.subparts && find(this.service.subparts, (row) => !row.status)) {
				validFlag = false;
			}
			this.checkAll = validFlag;
		},
		updateDates() {
			const start_date = new Date(this.service.start_date);
			const weekday = MomentJS(start_date).add(7, "days");
			this.service.end_date = this.formatDateTimeRaw(weekday);
		},
		onSubmit() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.serviceForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.serviceForm.validate()) {
				return false;
			}

			this.serviceButton = true;

			ApiService.patch(`${this.endpoint}/${this.typeUuid}/service-schedule`, this.service)
				.then(() => {
					this.$emit("success");
					EventBus.$emit("reload:asset-service", true);
				})
				.catch(() => {
					this.$emit("error");
				})
				.finally(() => {
					this.serviceButton = false;
				});
		},
		onUpdateSubmit() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.serviceForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.serviceForm.validate()) {
				return false;
			}

			this.serviceButton = true;

			ApiService.put(`asset/service-schedule/${this.serviceUuid}`, this.service)
				.then(() => {
					this.$emit("success");
					EventBus.$emit("reload:asset-service", true);
				})
				.catch(() => {
					this.$emit("error");
				})
				.finally(() => {
					this.serviceButton = false;
				});
		},
		getServiceTypes(data) {
			this.serviceTypeList = data;
		},
		getRelatedDetails() {
			let url = "";
			if (this.type == "Asset") {
				url = `asset/${this.typeUuid}`;
			} else if (this.type == "Asset Stock") {
				url = `asset-stock/${this.typeUuid}`;
			} else {
				url = `inventory/${this.typeUuid}`;
			}

			this.pageLoading = true;
			ApiService.get(url)
				.then(({ data }) => {
					this.relatedDetails = data;
					if (this.type != "Asset") {
						this.relatedDetails.cost_price_formatted = data.price_add_stock_formatted;
					}
				})
				.catch((error) => {
					//this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getSubParts() {
			let type = this.type ? this.type.toLowerCase() : "";
			ApiService.query(`all-subpart/${type}/${this.typeUuid}`)
				.then(({ data }) => {
					this.service.subparts = map(data, (row) => {
						row.status = false;
						return row;
					});
				})
				.catch((error) => {
					this.$emit("error", error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	mounted() {
		const today = new Date();
		this.service.start_date = this.formatDateTimeRaw(today);
		this.serviceTypeList = this.localDB("service_types", []);
		this.vendorList = this.localDB("suppliers", []);
		this.memberList = this.localDB("members", []);
		this.$nextTick(() => {
			this.updateDates();
		});
		this.asset_setting = this.localDB("asset_setting", {});
		this.service_setting = this.localDB("service_setting", {});
		this.getSubParts();
		this.getRelatedDetails();
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
		dialogWidth() {
			return toSafeInteger((document.body.clientWidth / 100) * 50);
		},
	},
};
</script>
