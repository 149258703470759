<template>
	<div>
		<CreateDialog :dialog="serviceDialog" v-on:close="$emit('close', true)" :back="false">
			<template v-slot:title>{{ type }} Service</template>
			<template v-slot:body>
				<v-form
					ref="serviceForm"
					v-model.trim="formValid"
					lazy-validation
					v-on:submit.stop.prevent="onSubmit"
				>
					<v-row v-if="serviceDialog" class="service-dialog">
						<v-col md="8">
							<v-row>
								<v-col md="12" v-if="relatedDetails && relatedDetails.name">
									<perfect-scrollbar
										:options="{ suppressScrollX: true }"
										class="scroll"
										style="max-height: 60vh; position: static"
									>
										<v-list class="border border-secondary light-blue-bg" subheader three-line>
											<v-list-item>
												<v-list-item-avatar v-if="relatedDetails">
													<ImageTemplate circle :src="relatedDetails.image"></ImageTemplate>
												</v-list-item-avatar>
												<v-list-item-content v-if="relatedDetails">
													<v-list-item-title class="font-size-16">
														<Chip color="blue darken-4" :text="relatedDetails.barcode"></Chip>
													</v-list-item-title>
													<v-list-item-subtitle class="font-size-14">
														<b>{{ relatedDetails.name }}</b>
													</v-list-item-subtitle>
												</v-list-item-content>
												<v-list-item-action class="margin-auto" v-if="relatedDetails">
													<v-list-item-action-text class="font-size-16"
														><b>Cost</b> {{ relatedDetails.cost_price_formatted }}
													</v-list-item-action-text>
													<v-list-item-action-text
														v-if="relatedDetails && relatedDetails.group_relation"
														class="font-size-16"
														><b>Category</b> {{ relatedDetails.group_relation.name }}
													</v-list-item-action-text>
												</v-list-item-action>
											</v-list-item>
										</v-list>
									</perfect-scrollbar>
								</v-col>
								<template v-if="service.serviceCategory == 1">
									<v-col md="4" class="py-0">
										<label :for="`service-from-${uniqFieldId}`" class="btx-label mt-3 required"
											>Item Availability</label
										>
									</v-col>
									<v-col md="8" class="py-0">
										<AutoCompleteInput
											hide-details
											:items="serviceCategories"
											:disabled="pageLoading"
											:loading="pageLoading"
											item-text="label"
											item-value="value"
											:is-custom="true"
											:id="`service-type-${uniqFieldId}`"
											placeholder="Service Category"
											v-model="service.type"
											:rules="[vrules.required(service.type, 'Service Category')]"
											:class="{
												required: !service.type,
											}"
										></AutoCompleteInput>
									</v-col>
									<template v-if="service.type == 2">
										<v-col md="4" class="my-auto py-0">
											<label :for="`service-from-${uniqFieldId}`" class="btx-label mt-3 required">From</label>
										</v-col>
										<v-col md="8" class="py-0">
											<DateTimePicker
												hide-details
												required
												:min-date="today"
												:disabled="pageLoading"
												:loading="pageLoading"
												:id="`service-from-${uniqFieldId}`"
												placeholder="From"
												v-model="service.from"
											></DateTimePicker>
										</v-col>
										<v-col md="4" class="my-auto py-0">
											<label :for="`service-to-${uniqFieldId}`" class="btx-label mt-3 required">To</label>
										</v-col>
										<v-col md="8" class="py-0">
											<DateTimePicker
												hide-details
												required
												:min-date="service.from"
												:disabled="pageLoading"
												:loading="pageLoading"
												:id="`service-to-${uniqFieldId}`"
												placeholder="To"
												v-model="service.to"
											></DateTimePicker>
										</v-col>
									</template>
									<template v-else>
										<v-col md="4" class="py-0"> </v-col>
										<v-col md="8" class="py-0">
											<v-checkbox
												dense
												hide-details
												label="Under service Indefinitely"
												class="mt-3 mb-0 p-0"
												:true-value="1"
												:false-value="0"
												v-model="service.indefinitely"
											></v-checkbox>
										</v-col>
										<template v-if="!service.indefinitely && false">
											<v-col md="4" class="my-auto py-0">
												<label :for="`service-from-${uniqFieldId}`" class="btx-label mt-3 required"
													>Duration</label
												>
											</v-col>
											<v-col md="8" class="py-0">
												<v-layout>
													<v-flex md6>
														<NumberInput
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															:id="`checkout-duration-${uniqFieldId}`"
															placeholder="Duration"
															v-model="service.duration"
															v-on:keyup="(e) => updateWarrantyDuration(e, 'duration')"
														></NumberInput>
													</v-flex>
													<v-flex md6>
														<SelectInput
															hide-details
															:items="warrantyDurationList"
															:disabled="pageLoading"
															:loading="pageLoading"
															placeholder="Duration"
															v-model="service.duration_type"
															v-on:change="(e) => updateWarrantyDuration(e, 'duration_type')"
														></SelectInput>
													</v-flex>
												</v-layout>
											</v-col>
										</template>
										<template v-if="!service.indefinitely">
											<v-col md="4" class="my-auto py-0">
												<label
													:for="`service-expected-completion-${uniqFieldId}`"
													class="btx-label mt-3 required"
													>Expected Completion {{ service.expected_completion }}</label
												>
											</v-col>
											<v-col md="8" class="py-0">
												<DateTimePicker
													hide-details
													:min-date="today"
													:required="service.indefinitely ? false : true"
													:disabled="pageLoading || (service.indefinitely ? true : false)"
													:loading="pageLoading"
													:id="`service-expected-completion-${uniqFieldId}`"
													placeholder="Expected Completion"
													v-model="service.expected_completion"
												></DateTimePicker>
											</v-col>
										</template>
									</template>
								</template>
								<template v-if="service.serviceCategory == 2">
									<RecurringService />
								</template>
								<v-col md="4" class="my-auto py-0">
									<label :for="`service-cost-${uniqFieldId}`" class="btx-label mt-3">Expected Cost</label>
								</v-col>
								<v-col md="8" class="py-0">
									<PriceInput
										hide-details
										:disabled="pageLoading"
										:loading="pageLoading"
										:id="`service-cost-${uniqFieldId}`"
										placeholder="Cost"
										v-model="service.cost"
									></PriceInput>
								</v-col>
								<v-col md="4" class="my-auto py-0">
									<label :for="`service-type-${uniqFieldId}`" class="btx-label mt-3 required"
										>Service Category</label
									>
								</v-col>
								<v-col md="8" class="py-0">
									<AutoCompleteInput
										hide-details
										:items="serviceTypeList"
										:disabled="pageLoading"
										:loading="pageLoading"
										:id="`service-type-${uniqFieldId}`"
										placeholder="Service Category"
										v-model="service.service_type"
										:rules="[vrules.required(service.service_type, 'Service Category')]"
										:class="{
											required: !service.service_type,
										}"
										v-on:click:append-outer="manageServiceTypeDialog = true"
										append-outer-icon="mdi-cog"
									></AutoCompleteInput>
								</v-col>
								<v-col md="4" class="my-auto py-0">
									<label :for="`service-performed-by-${uniqFieldId}`" class="btx-label mt-3"
										>Service Performed by</label
									>
								</v-col>
								<v-col md="8" class="py-0">
									<RadioInput
										row
										hide-details
										v-model="service.performed_by"
										:disabled="pageLoading"
										:id="`service-performed-by-${uniqFieldId}`"
										:items="performedItems"
									></RadioInput>
								</v-col>
								<v-col md="4" class="my-auto py-0">
									<label :for="`service-performed-by-${uniqFieldId}`" class="btx-label mt-3"
										>Service Location</label
									>
								</v-col>
								<v-col md="8" class="py-0">
									<RadioInput
										row
										hide-details
										v-model="service.location_by"
										:disabled="pageLoading"
										:id="`service-performed-by-${uniqFieldId}`"
										:items="performedPlaceItems"
									></RadioInput>
								</v-col>
								<template v-if="service.performed_by == 1">
									<v-col md="4" class="my-auto py-0">
										<label :for="`vendor-${uniqFieldId}`" class="btx-label mt-3">Supplier</label>
									</v-col>
									<v-col md="8" class="py-0">
										<AutoCompleteInput
											hide-details
											:items="vendorList"
											:disabled="pageLoading"
											:loading="pageLoading"
											:id="`vendor-${uniqFieldId}`"
											placeholder="Supplier"
											v-model="service.vendor"
										></AutoCompleteInput>
									</v-col>
								</template>
								<template v-else>
									<v-col md="4" class="my-auto py-0">
										<label :for="`member-${uniqFieldId}`" class="btx-label mt-3">Staff</label>
									</v-col>
									<v-col md="8" class="py-0">
										<AutoCompleteInput
											hide-details
											:items="memberList"
											:disabled="pageLoading"
											:loading="pageLoading"
											:id="`member-${uniqFieldId}`"
											placeholder="Staff"
											v-model="service.member"
										></AutoCompleteInput>
									</v-col>
								</template>
								<v-col md="4" class="py-0">
									<label :for="`description-${uniqFieldId}`" class="btx-label mt-3">Description</label>
								</v-col>
								<v-col md="8" class="py-0">
									<TextAreaInput
										hide-details
										:disabled="pageLoading"
										:loading="pageLoading"
										:id="`description-${uniqFieldId}`"
										placeholder="Description"
										v-model="service.description"
									></TextAreaInput>
								</v-col>
								<v-col md="12" class="font-level-3-bold pb-3 border-bottom-light-grey text-black mt-4 py-0">
									Attachments
								</v-col>
								<v-col md="12" class="py-0 mt-4">
									<template v-for="(data, index) in service.attachment">
										<v-row :key="`file-row-${index}`" :class="{ 'table-alternate-row': index % 2 }">
											<v-col md="5" class="py-0">
												<v-file-input
													v-model="data.file"
													placeholder="Click here to select files"
													color="blue darken-4"
													multiple
													outlined
													class="mt-3"
													prepend-icon=""
													prepend-inner-icon="mdi-attachment"
													hide-details
													v-on:change="limitAttachment($event, index)"
												></v-file-input>
											</v-col>
											<v-col md="5" class="py-0">
												<TextInput hide-details placeholder="File Name" v-model="data.name"></TextInput>
											</v-col>
											<v-col md="1" class="py-0">
												<v-btn
													:disabled="service.attachment.length < 2"
													v-on:click="removeFile(index)"
													class="white--text mt-3"
													depressed
													color="red darken-4"
													tile
													style="margin-left: -10px"
												>
													<v-icon>mdi-delete</v-icon>
												</v-btn>
											</v-col>
											<v-col md="1" class="py-0">
												<v-btn
													:disabled="service.attachment.length > 2"
													v-on:click="addMore()"
													class="white--text mt-3"
													depressed
													color="blue darken-4"
													tile
													style="margin-left: -13px"
												>
													<v-icon>mdi-plus</v-icon>
												</v-btn>
											</v-col>
										</v-row>
									</template>
								</v-col>
							</v-row>
						</v-col>
						<v-col md="4">
							<template v-if="asset_setting && asset_setting.subpart">
								<v-col md="12" class="font-level-3-bold pb-3 border-bottom-light-grey text-black mt-4 py-0">
									Sub-Parts <br />
									<span class="font-level-0 text-muted">
										<v-icon small>mdi-progress-question</v-icon> Asset Sub-Parts</span
									>
								</v-col>
								<v-col md="12" class="py-0">
									<div class="mt-3 overflow-y border-light-grey" style="max-height: calc(100vh - 337px)">
										<table width="100%" class="detail-table table-head-sticky">
											<thead>
												<tr>
													<th width="10%" class="p-2 light-blue-bg">
														<v-checkbox
															dense
															hide-details
															class="mt-3 mb-0 p-0"
															v-model="checkAll"
															v-on:change="manageAllSubpartCheck"
														></v-checkbox>
													</th>
													<th width="10%" class="p-2 light-blue-bg">Image</th>
													<th width="35%" class="p-2 light-blue-bg">Name</th>
													<th width="45%" class="p-2 light-blue-bg">Remarks</th>
												</tr>
											</thead>
											<tbody v-if="service.subparts.length">
												<tr v-for="(row, index) in service.subparts" :key="index">
													<td
														class="px-2 pb-3 font-level-1 text-black border-top-light-grey"
														v-on:click="viewSubpartDetails(row)"
													>
														<v-checkbox
															dense
															hide-details
															class="mt-3 mb-0 p-0"
															v-model="row.status"
															v-on:change="validateAllCheck"
														></v-checkbox>
													</td>
													<td
														class="p-2 border-top-light-grey cursor-pointer"
														v-on:click="viewSubpartDetails(row)"
													>
														<ImageTemplate circle style="max-width: 50px" :src="row.image"> </ImageTemplate>
													</td>
													<td
														class="px-2 pb-3 my-auto font-level-1 text-black border-top-light-grey cursor-pointer"
													>
														<p class="m-0 my-auto mt-3">{{ row.name }}</p>
														<p class="m-0 my-auto mt-1"># {{ row.barcode }}</p>
													</td>
													<td class="px-2 pb-3 font-level-1 text-black border-top-light-grey">
														<TextAreaInput
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															:rows="1"
															:id="`description-${uniqFieldId}`"
															placeholder="Description"
															v-model="row.remarks"
														></TextAreaInput>
													</td>
												</tr>
											</tbody>
											<tfoot v-else>
												<tr>
													<td colspan="4">
														<p class="m-0 row-not-found text-center py-3">
															<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
															Uhh... There are no subparts for this asset.
														</p>
													</td>
												</tr>
											</tfoot>
										</table>
									</div>
								</v-col>
							</template>
							<PerishablesDialog v-on:selected-erishables="selectedPerishables" />
						</v-col>
					</v-row>
				</v-form>
			</template>
			<template v-slot:action>
				<v-btn
					v-if="serviceUuid"
					class="white--text"
					:loading="pageLoading"
					:disabled="!formValid || pageLoading"
					depressed
					color="blue darken-4"
					tile
					v-on:click="onUpdateSubmit()"
				>
					Service Update
				</v-btn>
				<v-btn
					v-else
					class="white--text"
					:loading="pageLoading"
					:disabled="!formValid || pageLoading"
					depressed
					color="blue darken-4"
					tile
					v-on:click="onSubmit()"
				>
					Service
				</v-btn>
				<v-btn depressed tile :disabled="pageLoading" v-on:click="$emit('close', true)"> Close </v-btn>
			</template>
		</CreateDialog>
		<template v-if="manageServiceTypeDialog">
			<ManageServiceType
				:dialog="manageServiceTypeDialog"
				:serviceTypes="serviceTypeList"
				v-on:close-dialog="manageServiceTypeDialog = false"
				v-on:success="getServiceTypes"
			></ManageServiceType>
		</template>
		<template v-if="subpartDetailDialog">
			<Dialog :dialog="subpartDetailDialog" :dialog-width="subpartDialogWidth" v-on:close="subpartDetailDialog = false" :back="false">
				<template v-slot:title>{{ type }}</template>
				<template v-slot:body>
					<v-layout class="align-center light-blue-bg px-4 py-2">
						<v-flex md12>
							<v-layout class="justify-content-between align-items-center">
								<v-flex md5>
									<p class="my-auto font-level-3-bold">
										<span class="fw-500">
											<b class="text-truncate d-inline-block" style="max-width: 42%; vertical-align: middle">
												<ShowValue :object="subpartData" object-key="name" label="subpartData name"></ShowValue>
											</b>
											<v-chip color="cyan" class="ml-2 mr-2" label text-color="white"
												>{{ subpartData.barcode }}
											</v-chip>
											<template v-if="subpartData.is_fault == 1">
												<v-chip color="#1bc5bd" text-color="white" label class="mr-2"> Fault </v-chip>
											</template>
											<template v-if="subpartData.retired == 1">
												<v-chip color="#F44336" text-color="white" label> RETIRED </v-chip>
											</template>
											<template v-else>
												<template v-if="subpartData.status == 0">
													<v-chip color="#F44336" text-color="white" label> INACTIVE </v-chip>
												</template>
												<template v-else>
													<template v-if="subpartData.status == 1">
														<v-chip color="#4CAF50" text-color="white" label> ACTIVE </v-chip>
													</template>
												</template>
											</template>
										</span>
									</p>
								</v-flex>
							</v-layout>
							<div class="d-flex mt-4">
								<div class="w-10 pr-3">
									<ImageTemplate circle :src="subpartData.image"></ImageTemplate>
								</div>
								<div class="w-100 pr-3">
									<div v-if="subpartData.pico_barcode && subpartData.barcode_image" style="max-width: 300px">
										<div class="d-inline-block text-center">
											<img
												style="max-width: 100%; height: 30px"
												:src="subpartData.barcode_image"
												:alt="subpartData.pico_barcode"
											/>
											<pre class="mb-0 barcode-text bold-700">{{ subpartData.pico_barcode }}</pre>
										</div>
									</div>
									<div class="d-flex">
										<div class="w-33 pl-2">
											<div class="mt-2">
												<span class="fw-600">Sub-Part # : </span>
												<span class="fw-500 no-wrap">
													<ShowValue
														:object="subpartData"
														object-key="barcode"
														label="subpartData #"
													></ShowValue>
												</span>
											</div>
										</div>
										<div class="w-33 pl-2">
											<div class="mt-2">
												<span class="fw-600">Manufacturing Date : </span>
												<span class="fw-500 no-wrap">
													<ShowValue
														:object="subpartData"
														object-key="manufacturing_date_formatted"
														label="purchased date"
													>
													</ShowValue>
												</span>
											</div>
										</div>
										<div class="w-33 pl-2">
											<div class="mt-2">
												<span class="fw-600"> Contact for Item : </span>
												<span class="fw-500 no-wrap">
													<ShowValue :object="subpartData" object-key="contact" label="contact for item">
													</ShowValue>
												</span>
											</div>
										</div>
									</div>
									<div class="d-flex">
										<div class="w-33 pl-2">
											<div class="mt-2">
												<span class="fw-600 mr-2">Supplier : </span>
												<span class="fw-500">
													<span class="fw-500 no-wrap">
														<ShowValue
															:object="subpartData"
															object-key="supplier_relation.display_name"
															label="supplier"
														></ShowValue>
													</span>
												</span>
											</div>
										</div>
										<div class="w-33 pl-2">
											<div class="mt-2">
												<span class="fw-600"> Category : </span>
												<span class="fw-500 no-wrap">
													<ShowValue :object="subpartData" object-key="category_relation.name" label="Category">
													</ShowValue>
												</span>
											</div>
										</div>
										<div class="w-33 pl-2">
											<div class="mt-2">
												<span class="fw-600">Purchased Date : </span>
												<span class="fw-500 no-wrap">
													<ShowValue
														:object="subpartData"
														object-key="purchased_date_formatted"
														label="purchased date"
													>
													</ShowValue>
												</span>
											</div>
										</div>
									</div>
									<div class="d-flex">
										<div class="w-33 pl-2">
											<div class="mt-2">
												<span class="fw-600">Cost Price : </span>
												<span class="fw-500 no-wrap">
													<ShowValue :object="subpartData" object-key="cost_price_formatted" label="cost price">
													</ShowValue>
												</span>
											</div>
										</div>
										<div class="w-33 pl-2">
											<div class="mt-2">
												<span class="fw-600">Quantity : </span>
												<span class="fw-500 no-wrap">
													<ShowValue :object="subpartData" object-key="quantity" label="quantity"> </ShowValue>
												</span>
											</div>
										</div>
									</div>
								</div>
								<div v-if="false" class="w-20 pr-3">
									<template v-if="subpartData.pico_barcode && subpartData.barcode_image">
										<span class="fw-600">Pico Barcode : </span>
										<span class="fw-500">
											<Barcode circle :src="subpartData.barcode_image"></Barcode>
										</span>
									</template>
								</div>
							</div>
						</v-flex>
					</v-layout>

					<div class="mx-4">
						<div class="overflow-y" style="max-height: calc(100vh - 250px)">
							<v-layout class="p-4 border-bottom-light-grey min-height-57px">
								<v-flex class="font-level-3-bold my-auto">
									<span class="detail-svg-icon mr-2">
										<!--begin::Svg Icon-->
										<inline-svg :src="$assetURL('media/custom-svg/asset-color.svg')" />
										<!--end::Svg Icon-->
									</span>
									Sub-Part Details
								</v-flex>
							</v-layout>
							<table width="100%" class="detail-table scattered-table">
								<tr>
									<th width="250px">Warranty Start Date</th>
									<td>
										<ShowValue
											:object="subpartData"
											object-key="warranty_start_date"
											label="warranty Start Date"
										></ShowValue>
									</td>
									<th width="250px">Warranty End Date</th>
									<td>
										<ShowValue
											:object="subpartData"
											object-key="warranty_end_date"
											label="warranty End Date"
										></ShowValue>
									</td>
								</tr>
								<tr>
									<th width="250px">Warranty Reminder Date</th>
									<td>
										<ShowValue
											:object="subpartData"
											object-key="warranty_reminder_date"
											label="warranty Reminder Date"
										></ShowValue>
									</td>
									<th width="250px">Warranty Reminder End Date</th>
									<td>
										<ShowValue
											:object="subpartData"
											object-key="warranty_reminder_endDate_formatted"
											label="Warranty Reminder End Date"
										></ShowValue>
									</td>
								</tr>
								<tr>
									<th width="250px">Maintenance Start Date</th>
									<td>
										<ShowValue
											:object="subpartData"
											object-key="maintenance_start_date_formatted"
											label="Maintenance Start Date"
										></ShowValue>
									</td>
									<th width="250px">Maintenance End Date</th>
									<td>
										<ShowValue
											:object="subpartData"
											object-key="maintenance_end_date_formatted"
											label="Maintenance End Date"
										></ShowValue>
									</td>
								</tr>
								<tr>
									<th width="250px">Maintenance Reminder Date</th>
									<td>
										<ShowValue
											:object="subpartData"
											object-key="maintenance_reminder_date_formatted"
											label="Maintenance Reminder Date"
										></ShowValue>
									</td>
									<th width="250px">Maintenance Reminder End Date</th>
									<td>
										<ShowValue
											:object="subpartData"
											object-key="maintenance_reminder_endDate_formatted"
											label="Maintenance Reminder End Date"
										></ShowValue>
									</td>
								</tr>
								<tr>
									<th width="250px">Maintenance By</th>
									<td>
										<template v-if="subpartData.maintenance_by">
											<ShowValue
												:object="subpartData"
												object-key="maintenance_by_relation.display_name"
												label="Maintenance By"
											></ShowValue>
											(Supplier)
										</template>
										<template v-else>
											<ShowValue
												:object="subpartData"
												object-key="member.display_name"
												label="Maintenance By"
											></ShowValue>
											(Inchagre Officer)
										</template>
									</td>
									<th width="250px">Cost of Maintenance</th>
									<td>
										<ShowValue
											:object="subpartData"
											object-key="maintenance_cost_formatted"
											label="Cost of Maintenance"
										></ShowValue>
									</td>
								</tr>
								<tr>
									<th width="250px">Created On</th>
									<td colspan="3">
										<ShowValue
											:object="subpartData"
											object-key="created_at"
											tooltip
											tooltip-value="added_at_formatted"
											label="created on"
										></ShowValue>
									</td>
								</tr>
								<tr>
									<th width="250px">Description</th>
									<td colspan="3">
										<ShowValue :object="subpartData" object-key="description" label="description">
										</ShowValue>
									</td>
								</tr>
							</table>
						</div>
					</div>
				</template>
				<template v-slot:action>
					<v-btn depressed tile :disabled="pageLoading" v-on:click="subpartDetailDialog = false"> Close </v-btn>
				</template>
			</Dialog>
		</template>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import { toSafeInteger, cloneDeep, findIndex, map, find } from "lodash";
import CreateDialog from "@/view/components/CreateDialog";
import ApiService from "@/core/services/api.service";
import { UploadFiles } from "@/core/lib/upload.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import RadioInput from "@/view/components/RadioInput";
//import QuantityInput from "@/view/components/QuantityInput";
import PriceInput from "@/view/components/PriceInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import DateTimePicker from "@/view/components/DateTimePicker";
import TextInput from "@/view/components/TextInput";
import ImageTemplate from "@/view/components/Image";
import Chip from "@/view/components/Chip";
import MomentJS from "moment-timezone";
import SelectInput from "@/view/components/SelectInput";
import NumberInput from "@/view/components/NumberInput";
import PerishablesDialog from "@/view/components/PerishablesDialog";

//import UploadFile from "@/view/components/UploadFile";
//import { GetLocationByInventory } from "@/core/lib/inventory.lib";
import { EventBus } from "@/core/event-bus/event.bus";
import ManageServiceType from "@/view/components/Manage-Service-Type.vue";

import ObjectPath from "object-path";
import RecurringService from "@/view/components/RecurringService";
import Dialog from "@/view/components/Dialog";
import ShowValue from "@/view/components/ShowValue";

export default {
	name: "service-template",
	title: "Service Template",
	props: {
		type: {
			type: String,
			default: null,
		},
		typeUuid: {
			type: String,
			default: null,
		},
		endpoint: {
			type: String,
			default: null,
		},
		serviceDialog: {
			type: Boolean,
			default: false,
		},
		serviceArray: {
			type: Object,
			default: null,
		},
	},
	watch: {
		serviceDialog(param) {
			if (param) {
				this.init();
			}
		},
		serviceArray(param) {
			if (param) {
				this.service = {
					type: param.type,
					from: param.actual_start_date,
					to: param.actual_end_date,
					cost: param.cost,
					expected_completion: param.expected_end_date,
					inventories: [],
					attachments: [],
					service_type: param.service_type,
					performed_by: param.service_by,
					location_by: param.location_by,
					indefinitely: param.indefinitely,
					vendor: param.supplier,
					member: param.member,
					description: param.description,
				};
				this.serviceUuid = param.uuid;
			}
		},
	},
	data() {
		return {
			formValid: true,
			pageLoading: false,
			checkAll: false,
			subpartDetailDialog: false,
			service_setting: {},
			relatedDetails: {},
			subpartData: {},
			subparts: [],
			asset_setting: {},
			serviceTypeList: [],
			vendorList: [],
			memberList: [],
			inventoryList: [],
			manageServiceTypeDialog: false,
			inventory: {},
			serviceUuid: null,
			service: {
				type: 1,
				serviceCategory: 1,
				from: null,
				to: null,
				cost: null,
				expected_completion: null,
				inventories: [],
				attachment: [
					{
						file: null,
						url: null,
						name: null,
					},
				],
				service_type: null,
				performed_by: 1,
				location_by: 1,
				indefinitely: 0,
				vendor: 0,
				member: 0,
				description: null,
				subparts: [],
				duration: 0,
				duration_type: "day",
			},

			serviceCategories: [
				{
					label: "Make Item Unavailable",
					value: 1,
					description:
						"(Item can't be used, can't be issued, can't be kept with the user, If allocated already then have to return back)",
				},
				{
					label: "Create Service Record",
					value: 2,
					description: "(Only For Future)",
				},
			],
			serviceType: [
				{
					label: "One-off Service",
					value: 1,
					description: "A one time service",
				},
				{
					label: "Recurring Service",
					value: 2,
					description: "A contract service with repeating services",
				},
			],
			performedItems: [
				{
					label: "Supplier",
					value: 1,
				},
				{
					label: "Staff",
					value: 2,
				},
			],
			performedPlaceItems: [
				{
					label: "Onsite",
					value: 1,
				},
				{
					label: "Workshop",
					value: 2,
				},
			],
		};
	},
	components: {
		CreateDialog,
		RadioInput,
		DateTimePicker,
		ShowValue,
		RecurringService,
		ManageServiceType,
		//	QuantityInput,
		SelectInput,
		NumberInput,
		ImageTemplate,
		PriceInput,
		PerishablesDialog,
		TextAreaInput,
		Chip,
		AutoCompleteInput,
		TextInput,
		Dialog,
	},
	methods: {
		viewSubpartDetails(data) {
			this.subpartData = data;
			this.subpartDetailDialog = true;
		},
		selectedPerishables(data) {
			this.service.inventories = data;
		},
		updateWarrantyDuration(e, type) {
			let start_date = e;
			if (type != "start_date") {
				start_date = this.service && this.service.start_date ? this.service.start_date : null;
			}
			//alert(start_date);
			if (start_date) {
				let { duration_type, duration } = this.service;
				let date = MomentJS(start_date, "YYYY-MM-DD");
				duration = duration ? toSafeInteger(duration) : 0;
				switch (duration_type) {
					case "day":
						date = MomentJS(start_date, "YYYY-MM-DD").add(duration, "d");
						break;
					case "month":
						date = MomentJS(start_date, "YYYY-MM-DD").add(duration, "M");
						break;
					case "year":
						date = MomentJS(start_date, "YYYY-MM-DD").add(duration, "y");
						break;
				}
				/* this.$nextTick(() => {
					this.service.end_date = date.format("YYYY-MM-DD");
				}); */
				this.service.expected_completion = date.format("YYYY-MM-DD");
			}
		},
		getRelatedDetails() {
			let url = "";
			if (this.type == "Asset") {
				url = `asset/${this.typeUuid}`;
			} else if (this.type == "Asset Stock") {
				url = `asset-stock/${this.typeUuid}`;
			} else {
				url = `inventory/${this.typeUuid}`;
			}

			this.pageLoading = true;
			ApiService.get(url)
				.then(({ data }) => {
					this.relatedDetails = data;
					if (this.type != "Asset") {
						this.relatedDetails.cost_price_formatted = data.price_add_stock_formatted;
					}
				})
				.catch((error) => {
					//this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		manageAllSubpartCheck(val) {
			this.service.subparts = map(this.service.subparts, (row) => {
				row.status = val;
				return row;
			});
		},
		validateAllCheck() {
			let validFlag = true;
			if (this.service.subparts && find(this.service.subparts, (row) => !row.status)) {
				validFlag = false;
			}
			this.checkAll = validFlag;
		},
		addMore() {
			this.service.attachment.push({
				file: null,
				url: null,
				name: null,
			});
		},
		removeFile(index) {
			this.service.attachment.splice(index, 1);
			if (this.service.attachment.length < 1) {
				this.addMore();
			}
		},
		limitAttachment(output, index) {
			if (this.pageLoading) {
				return false;
			}

			const attachment = output.slice(0, 3);

			if (attachment.length <= 0) {
				return false;
			}

			const request = new FormData();

			for (let i = 0; i < attachment.length; i++) {
				request.append(`files[${i}]`, attachment[i]);
			}

			this.pageLoading = true;

			UploadFiles(request)
				.then((response) => {
					this.service.attachment[index].url = ObjectPath.get(response, "0.path", null);
					this.service.attachment[index].name = ObjectPath.get(response, "0.name", null);
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		init() {
			this.service = new Object({
				type: 1,
				from: null,
				to: null,
				cost: null,
				expected_completion: null,
				inventories: [],
				attachment: [
					{
						file: null,
						url: null,
						name: null,
					},
				],
				service_type: null,
				performed_by: 1,
				location_by: 1,
				indefinitely: 0,
				vendor: 0,
				member: 0,
				description: null,
			});
		},
		selectInventory() {
			const inventory = cloneDeep(this.inventory);
			this.inventory = new Object({});
			let preInventory = findIndex(this.service.inventories, { id: inventory.value });
			if (preInventory != -1) {
				this.service.inventories[preInventory].quantity =
					toSafeInteger(this.service.inventories[preInventory].quantity) + 1;
			} else {
				this.service.inventories.push({
					id: inventory.value,
					uuid: inventory.uuid,
					name: inventory.text,
					quantity: 1,
				});
			}
		},
		onSubmit() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.serviceForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.serviceForm.validate()) {
				return false;
			}

			this.pageLoading = true;

			ApiService.patch(`${this.endpoint}/${this.typeUuid}/service-start`, this.service)
				.then(() => {
					this.$emit("success");
					EventBus.$emit("reload:asset-service", true);
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		onUpdateSubmit() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.serviceForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.serviceForm.validate()) {
				return false;
			}

			this.pageLoading = true;

			ApiService.put(`${this.endpoint}/service-start/${this.serviceUuid}`, this.service)
				.then(() => {
					this.$emit("success");
					EventBus.$emit("reload:asset-service", true);
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		removeInventory(index) {
			let inventoryArr = this.service.inventories;
			inventoryArr.splice(index, 1);
			this.service.inventories = inventoryArr;
		},
		getServiceTypes(data) {
			this.serviceTypeList = data;
		},
		getSubParts() {
			let type = this.type ? this.type.toLowerCase() : "";
			ApiService.query(`all-subpart/${type}/${this.typeUuid}`)
				.then(({ data }) => {
					this.service.subparts = map(data, (row) => {
						row.status = false;
						return row;
					});
				})
				.catch((error) => {
					this.$emit("error", error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	mounted() {
		this.serviceTypeList = this.localDB("service_types", []);
		this.vendorList = this.localDB("suppliers", []);
		this.memberList = this.localDB("members", []);
		this.inventoryList = this.localDB("inventories", []);
		this.asset_setting = this.localDB("asset_setting", {});
		this.service_setting = this.localDB("service_setting", {});
		this.getSubParts();
		this.getRelatedDetails();
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
		dialogWidth() {
			return toSafeInteger((document.body.clientWidth / 100) * 50);
		},
		subpartDialogWidth() {
			return toSafeInteger((document.body.clientWidth / 100) * 70);
		},
	},
};
</script>
