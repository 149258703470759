<template>
	<v-radio-group
		dense
		mandatory
		:class="customClass"
		id="id"
		v-model="radioinput"
		:row="row"
		v-on:change="$emit('change', radioinput)"
		:hide-details="hideDetails"
	>
		<v-row>
			<v-col v-for="(item, index) in items" :key="index">
				<label
					class="custom-input custom-radio rounded cursor-pointer py-0"
					:class="radioinput ==  item.value ? 'checkbox-border-selected' : 'checkbox-border'"
				>
					<div class="ma-1">
						<v-radio :disabled="disabled" :value="item.value" class="mt-3"></v-radio>
					</div>
					<div class="flex-grow-1 pb-2 text-primary">
						<div class="d-flex align-center" style="height: 34px">
							<h6 class="cr-title">{{ item.label }}</h6>
						</div>
						<p class="text-sm mb-0" :class="radioinput ==  item.value ? 'cr-desc-selected' : 'cr-desc'">
							{{ item.description }}
						</p>
					</div>
				</label>
			</v-col>
		</v-row>
	</v-radio-group>
</template>

<script>
export default {
	name: "new-radio-input",
	title: "Radio Input",
	model: {
		prop: "value",
		event: "change",
	},
	props: {
		value: {
			type: [Boolean, Number, String],
			default: null,
		},
		items: {
			type: Array,
			required: true,
			default: () => {
				return [];
			},
		},
		id: {
			type: String,
			default: null,
		},
		hideDetails: {
			type: Boolean,
			default: false,
		},
		customClass: {
			type: String,
			default: "mt-3 pt-0",
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		row: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		value: {
			deep: true,
			immediate: true,
			handler(param) {
				this.radioinput = param;
			},
		},
	},
	data() {
		return {
			radioinput: null,
		};
	},
	mounted() {
		this.radioinput = this.value;
	},
};
</script>
<style lang="scss" scoped>
.custom-radio {
	display: flex;
	align-items: flex-start;
	gap: 0.375rem;
	&.active {
		background: rgba(var(--v-theme-primary), 0.1);
	}
	.v-radio {
		margin-block-start: -0.25rem;
	}

	.cr-title {
		font-weight: 500;
        color:#5e5c5c;
	}
    .cr-desc
    {
        color:#a39e9e;
    }
    .cr-desc-selected
    {
        color:#ef8b33;
    }
}
.checkbox-border {
	border: 1px solid #ccc !important;
	background-color: #b2b8bf3b !important;
}
.checkbox-border-selected {
	border: 1px solid #ef8b33 !important;
	background-color: #efd0b561 !important;
}
</style>
